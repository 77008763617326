import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchData } from './api';
import { saveTournaments, saveTournamentsArchive } from './db/saveTournaments';
import TournamentList from './tournaments/TournamentList';
import { Box } from '@mui/material';
import MainMenuButton from './menu/MainMenuButton';
import './App.css';
import { useSelector } from 'react-redux';
import { cleanAndSaveJudges } from './db/managementjudges';

import { getIdCodeWithTimestamp } from './redux/selectors';
function App() {
  const [scanResult, setScanResult] = useState(null);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getData = async () => {
      try {
        const data = await fetchData('info.php', {}, navigate);
        if (data && data.success) {
          const { tournaments, archive, managementjudges } = data;
          //   if (tournaments.length > 0)
          await saveTournaments(tournaments);
          await cleanAndSaveJudges(managementjudges);
          await saveTournamentsArchive(archive);

          setLoading(true);
        } else if (data === 'ERR_NETWORK') {
          setLoading(true);
        }
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      }
    };

    getData();
  }, [navigate]);

  return (
    <div>
      <Box className='content'>{loading && <TournamentList />}</Box>
      <MainMenuButton />
    </div>
  );
}

export default App;
