export const SET_ONLINE_STATUS = 'SET_ONLINE_STATUS';
export const SET_ID_CODE = 'SET_ID_CODE';
export const SET_ID_USER = 'SET_ID_USER';
export const setOnlineStatus = isOnline => ({
  type: SET_ONLINE_STATUS,
  payload: isOnline,
});

export const setIdCode = id_code => ({
  type: SET_ID_CODE,
  payload: id_code,
});

export const setIdUser = id_user => ({
  type: SET_ID_USER,
  payload: id_user,
});
