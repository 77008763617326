import React, { useState, useImperativeHandle, forwardRef } from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  COMPETITION_FORMAT,
  COMPETITION_FORMAT_DIS_SELECT,
} from '../../settings';
const TournamentNature = forwardRef(({ value, onChange }, ref) => {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');

  useImperativeHandle(ref, () => ({
    validate: () => {
      if (value) {
        setError(false);
        setHelperText('');
        return true;
      } else {
        setError(true);
        setHelperText('Выберите характер соревнования');
        return false;
      }
    },
  }));
  const handleInputChange = event => {
    setError(false);
    setHelperText('');

    onChange(event.target.value);
  };

  return (
    <FormControl sx={{ width: '100%', mt: 1 }} size='small' error={error}>
      <InputLabel id='tournament-nature-label'>
        Характер соревнования
      </InputLabel>
      <Select
        labelId='tournament-nature-label'
        id='tournament-nature'
        value={value}
        label='Характер соревнования'
        onChange={handleInputChange}
      >
        <MenuItem value={COMPETITION_FORMAT.PERSONAL}>
          <div>
            <div>{COMPETITION_FORMAT.PERSONAL}</div>
            <small style={{ color: 'gray' }}>
              {COMPETITION_FORMAT_DIS_SELECT.PERSONAL}
            </small>
          </div>
        </MenuItem>
        <MenuItem value={COMPETITION_FORMAT.TEAM}>
          <div>
            <div>{COMPETITION_FORMAT.TEAM}</div>
            <small style={{ color: 'gray' }}>
              {COMPETITION_FORMAT_DIS_SELECT.TEAM}
            </small>
          </div>
        </MenuItem>
        <MenuItem value={COMPETITION_FORMAT.PERSONAL_TEAM}>
          <div>
            <div>{COMPETITION_FORMAT.PERSONAL_TEAM}</div>
            <small style={{ color: 'gray' }}>
              {COMPETITION_FORMAT_DIS_SELECT.PERSONAL_TEAM}
            </small>
          </div>
        </MenuItem>
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
});
export default TournamentNature;
