import db from '../../db';

export const getUser = async () => {
  try {
      const user = await db.users.toArray();
      return user;
  } catch (error) {
    console.error('Ошибка getUser:', error);
  }
};
