import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { TextField, Typography, ListItemText, ListItem } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { WATER } from '../../settings';
const NameWaterInput = forwardRef(({ value, onChange }, ref) => {
  const [error, onError] = useState(false);
  const [helperText, serHelperText] = useState('');
  const [selectedWater, setSelectedWater] = useState(null);
  const [waterOptions, setWaterOptions] = useState(WATER);

  const handleInputChange = (event, newInputValue) => {
    console.log(newInputValue);
    onChange(newInputValue);
    if (newInputValue.length >= 4) {
      onError(false);
      serHelperText('');
    }
  };

  const handleValueChange = (event, newValue) => {
    setSelectedWater(newValue);
  };

  useImperativeHandle(ref, () => ({
    validate: () => {
      if (value.length < 4) {
        onError(true);
        serHelperText(
          'Выберите название водоема из списка или введите новое название. Название должно быть не менее 4 символов',
        );
        return false;
      } else {
        onError(false);
        serHelperText('');
        return true;
      }
    },
  }));
  return (
    <Autocomplete
      value={selectedWater}
      onChange={handleValueChange}
      inputValue={value}
      onInputChange={handleInputChange}
      options={waterOptions}
      getOptionLabel={option => option.title}
      renderOption={(props, option) => {
        const { key, ...restProps } = props;
        return (
          <ListItem key={key} {...restProps}>
            <ListItemText
              primary={option.title}
              secondary={
                <Typography variant='body2' color='textSecondary'>
                  {option.address}
                </Typography>
              }
            />
          </ListItem>
        );
      }}
      renderInput={params => (
        <TextField
          {...params}
          label='Название водоема'
          variant='outlined'
          helperText={helperText}
          error={error}
          fullWidth
          margin='dense'
          InputLabelProps={{ shrink: true }}
        />
      )}
      freeSolo
    />
  );
});

export default NameWaterInput;
