// Редьюсеры (reducers)
import { SET_ONLINE_STATUS, SET_ID_CODE, SET_ID_USER } from './actions';

const initialState = {
  isOnline: true, // Начальное состояние - онлайн
  id_user: 0,
  id_code: 0,
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ONLINE_STATUS:
      return {
        ...state,
        isOnline: action.payload,
      };
    case SET_ID_CODE:
      return {
        ...state,
        id_code: action.payload,
      };
    case SET_ID_USER:
      return {
        ...state,
        id_user: action.payload,
      };

    default:
      return state;
  }
};

export default rootReducer;
