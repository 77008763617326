import React, { useState, useImperativeHandle, forwardRef } from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';
import { FormControl } from '@mui/material';
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(localizedFormat);
dayjs.locale('ru');

const StartDateTournament = forwardRef(({ value, onChange }, ref) => {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  console.log(value);
  useImperativeHandle(ref, () => ({
    validate: () => {
      if (value && dayjs(value).isValid()) {
        setError(false);

        setHelperText('');
        return true;
      } else {
        setError(true);
        setHelperText('Введите дату старта');
        return false;
      }
    },
  }));

  const handleInputChange = date => {
    if (dayjs(date).isValid()) {
      setError(false);
      setHelperText('');
    } else {
      setError(true);
      setHelperText('Введите дату старта');
    }
    onChange(date);
  };

  return (
    <FormControl fullWidth margin='dense'>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
        <DatePicker
          label='Дата начала'
          inputFormat='DD.MM.YYYY'
          value={value}
          sx={{ width: '100%' }}
          onChange={handleInputChange}
          fullWidth
          margin='dense'
          InputLabelProps={{ shrink: true }}
          slotProps={{
            textField: {
              error: error,
              helperText: helperText,
            },
          }}
        />
      </LocalizationProvider>
    </FormControl>
  );
});

export default StartDateTournament;
