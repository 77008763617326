import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Box, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { ROLE } from '../../settings';
import { getManagementJudgesByTournamentId } from '../../db/managementjudges';
import AddJudgeModal from '../AddJudgeModal';
import { deleteAndFetchRemainingJudges } from '../../db/managementjudges';
import TaskQueue from '../../taskQueue/TaskQueue';
import db from '../../db';

const BlockManagementJudges = ({ tournamentId, currentUserId }) => {
  const [expanded, setExpanded] = useState(false);
  const [judges, setJudges] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const taskQueue = new TaskQueue(db);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    updateJudgesList();
  }, [modalOpen]);

  const updateJudgesList = () => {
    getManagementJudgesByTournamentId(tournamentId).then(judges => {
      setJudges(judges || []);
      const currentUser = (judges || []).find(
        judge => judge.user_id === currentUserId,
      );
      if (currentUser) {
        setUserRole(currentUser.role);
      }
    });
  };

  const renderJudgesByRole = role => {
    return (judges || [])
      .filter(judge => judge.role === role)
      .map(judge => (
        <Grid container key={judge.user_id} alignItems='center'>
          <Grid item xs>
            <Typography variant='body2'>
              {judge.name} ({judge.rank}) {judge.contacts}
            </Typography>
          </Grid>
          <Grid item>
            {((role !== ROLE.ORGANIZER && userRole === ROLE.ORGANIZER) ||
              (userRole === ROLE.CHIEF_JUDGE &&
                role !== ROLE.ORGANIZER &&
                role !== ROLE.CHIEF_JUDGE)) && (
              <Button
                color='error'
                size='small'
                onClick={() => handleRemoveJudge(judge.user_id, role)}
              >
                Удалить
              </Button>
            )}
          </Grid>
        </Grid>
      ));
  };

  const handleRemoveJudge = async (userId, role) => {
    const id_code_with_timestamp = `${currentUserId}${Math.floor(
      Date.now() / 1000,
    )}`;

    const remainingJudges = await deleteAndFetchRemainingJudges(
      userId,
      tournamentId,
      role,
    );
    setJudges(remainingJudges);

    const taskData = {
      id: id_code_with_timestamp,
      userId,
      tournamentId,
      role,
    };
    await taskQueue.addTask(
      'deleteJudges',
      taskData,
      id_code_with_timestamp,
      'deleteJudges',
      userId,
    );
  };

  const requiredRoles = [
    { role: ROLE.ORGANIZER, label: ROLE.ORGANIZER },
    { role: ROLE.CHIEF_JUDGE, label: ROLE.CHIEF_JUDGE },
    { role: ROLE.CHIEF_SECRETARY, label: ROLE.CHIEF_SECRETARY },
  ];

  const otherRoles = [
    { role: ROLE.ASSISTANT_CHIEF_JUDGE, label: ROLE.ASSISTANT_CHIEF_JUDGE },
    {
      role: ROLE.ASSISTANT_CHIEF_SECRETARY,
      label: ROLE.ASSISTANT_CHIEF_SECRETARY,
    },
    { role: ROLE.SENIOR_JUDGE, label: ROLE.SENIOR_JUDGE },
    { role: ROLE.JUDGE, label: ROLE.JUDGE },
    { role: ROLE.JUDGE_CONTROLLER, label: ROLE.JUDGE_CONTROLLER },
    { role: ROLE.JUDGE_SECRETARY, label: ROLE.JUDGE_SECRETARY },
  ];

  const handleOpenModal = () => {
    setSelectedRole('');
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedRole('');
    updateJudgesList();
  };

  useEffect(() => {
    const hasRequiredJudges = requiredRoles.some(({ role }) => {
      return renderJudgesByRole(role).length === 0;
    });
    setExpanded(hasRequiredJudges);
  }, []);

  return (
    <Paper style={{ padding: 16, marginTop: 16 }}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
        >
          <div>
            <Typography variant='h6'>Судейская коллегия</Typography>
          </div>
          <div>
            <Typography
              variant='caption'
              display='block'
              sx={{ color: 'gray' }}
            >
              Распределение ролей судейской коллегии, добавление и удаление
              судей.
            </Typography>
          </div>
        </Box>

        <IconButton aria-label='toggle' size='small' onClick={toggleExpand}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      {expanded && (
        <>
          {requiredRoles.map(({ role, label }) => (
            <div key={role} style={{ marginTop: 16 }}>
              <Typography variant='subtitle1' style={{ fontWeight: 'bold' }}>
                {label}:
              </Typography>
              {renderJudgesByRole(role).length > 0 ? (
                renderJudgesByRole(role)
              ) : (
                <Typography variant='body2' style={{ color: 'red' }}>
                  (необходимо добавить)
                </Typography>
              )}
            </div>
          ))}

          {otherRoles.map(({ role, label }) => {
            const judges = renderJudgesByRole(role);
            return judges.length > 0 ? (
              <div key={role} style={{ marginTop: 16 }}>
                <Typography variant='subtitle1' style={{ fontWeight: 'bold' }}>
                  {label}:
                </Typography>
                {judges}
              </div>
            ) : null;
          })}

          {(userRole === ROLE.CHIEF_JUDGE || userRole === ROLE.ORGANIZER) && (
            <Box sx={{ textAlign: 'end', mt: '20px' }}>
              <Button
                variant='contained'
                color='primary'
                style={{ marginTop: 8 }}
                onClick={handleOpenModal}
              >
                Добавить
              </Button>
            </Box>
          )}

          <AddJudgeModal
            open={modalOpen}
            handleClose={handleCloseModal}
            tournamentId={tournamentId}
            role={selectedRole}
            updateJudgesList={updateJudgesList}
          />
        </>
      )}
    </Paper>
  );
};

export default BlockManagementJudges;
