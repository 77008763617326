import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { TextField } from '@mui/material';

const CountCups = forwardRef(({ value, onChange }, ref) => {
  const [error, onError] = useState(false);
  const [helperText, serHelperText] = useState(
    'Настройка дополнительных этапов - после создания турнира',
  );

  useImperativeHandle(ref, () => ({
    validate: () => {
      if (value < 1 || value > 5) {
        onError(true);
        serHelperText('Количество этапов может быть от 1 до 5');
        return false;
      } else {
        onError(false);
        serHelperText(
          'Настройка дополнительных этапов - после создания турнира',
        );
        return true;
      }
    },
  }));
  const handleInputChange = event => {
    if (value >= 1 || value <= 5) {
      onError(false);
      serHelperText('Настройка дополнительных этапов - после создания турнира');
    }
    onChange(event.target.value);
  };

  return (
    <TextField
      id='outlined-number'
      label='Количество этапов'
      type='text'
      inputMode='numeric'
      helperText={helperText}
      onChange={handleInputChange}
      value={value}
      sx={{ width: '100%' }}
      error={error}
      fullWidth
      margin='dense'
      InputLabelProps={{ shrink: true }}
    />
  );
});
export default CountCups;
