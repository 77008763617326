// ScanQRCode.js
import React, { useEffect } from 'react';
import { Html5Qrcode } from 'html5-qrcode';

const ScanQRCode = ({ onScanSuccess, onScanError, qrboxSize = 250 }) => {
  useEffect(() => {
    const html5QrCode = new Html5Qrcode('reader');

    html5QrCode
      .start(
        { facingMode: 'environment' },
        {
          fps: 10,
          qrbox: qrboxSize,
        },
        onScanSuccess,
        onScanError,
      )
      .catch(err => {
        console.error('Unable to start scanning', err);
      });

    return () => {
      html5QrCode
        .stop()
        .then(() => {
          console.log('QR Code scanning stopped.');
        })
        .catch(err => {
          console.error('Unable to stop scanning', err);
        });
    };
  }, [onScanSuccess, onScanError, qrboxSize]);

  return <div id='reader' style={{ width: '100%' }}></div>;
};

export default ScanQRCode;
