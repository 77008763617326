import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import MainInfo from './comp/MainInfo';

const CreateTournament = () => {
  const navigate = useNavigate();

  const handleBackApp = () => {
    navigate(-1);
  };

  return (
    <>
      <Box
        sx={{
          marginTop: 3,
          marginLeft: 1,
          marginRight: 1,
        }}
      >
        <IconButton
          edge='start'
          color='inherit'
          onClick={handleBackApp}
          size='small'
        >
          <ArrowBackIcon /> назад
        </IconButton>
      </Box>
      <Box
        sx={{
          marginTop: 1,
          marginLeft: 1,
          marginRight: 1,
          textAlign: 'center',
        }}
      >
        <Typography variant='h5' gutterBottom>
          Создание нового соревнования дисциплина "ЛОВЛЯ КАРПА"
        </Typography>
      </Box>
      <MainInfo />
    </>
  );
};

export default CreateTournament;
