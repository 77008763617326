import React from 'react';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { BIG_FISH_DAY, FIRST_FISH } from '../../settings';
const DopNominationsSelect = ({ nominations, onChange }) => {
  const handleNominationsChange = event => {
    const { value } = event.target;
    const newNominations = nominations.includes(value)
      ? nominations.filter(nomination => nomination !== value)
      : [...nominations, value];
    onChange(newNominations);
  };

  return (
    <FormControl component='fieldset'>
      <FormLabel component='legend'>
        Дополнительные номинации (можно выбрать несколько)
      </FormLabel>
      <FormControlLabel
        control={
          <Checkbox
            checked={nominations.includes(BIG_FISH_DAY)}
            onChange={handleNominationsChange}
            value={BIG_FISH_DAY}
          />
        }
        label='Big fish суток'
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={nominations.includes(FIRST_FISH)}
            onChange={handleNominationsChange}
            value={FIRST_FISH}
          />
        }
        label='Первая пойманная рыба'
      />
    </FormControl>
  );
};

export default DopNominationsSelect;
