import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getTournamentById,
  getTournamentsByStagesCupId,
} from '../db/Tournaments/getAllTournaments';
import BlockManagementJudges from './comp/BlockManagementJudges';
import BlockGeneralInformation from './comp/BlockGeneralInformation';
import BlockSocialNetworks from './comp/BlockSocialNetworks';
import BlockSProtocolConstructor from './comp/BlockSProtocolConstructor';
import BlockDrawSettings from './comp/BlockDrawSettings';
import BlockRegistrations from './comp/BlockRegistrations';
import BlockWeighing from './comp/BlockWeighing';
import BlockEditingWeighting from './comp/BlockEditingWeighting';
import BlockDraw from './comp/BlockDraw';
import BlockPrintingDocuments from './comp/BlockPrintingDocuments';
import BlockAdditionalNominations from './comp/BlockAdditionalNominations';
import { Grid, Box, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getManagementJudgesByTournamentId } from '../db/managementjudges';
import db from '../db';
import TaskQueue from '../taskQueue/TaskQueue';
import { ROLE } from '../settings';

const Tournament = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [tournament, setTournament] = useState(null);
  const [tournamentJudges, setTournamentJudges] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const navigate = useNavigate();
  const [id_code, setid_code] = useState(null);
  const [currentTournament, set_currentTournament] = useState(null);
  const taskQueue = new TaskQueue(db);
  const [tournament_name, settournament_name] = useState('');
  const sendTaskQueue = async (
    type,
    data,
    api,
    response = null,
    resp_id_code = null,
  ) => {
    const _id_code = resp_id_code ? resp_id_code : id_code;

    const result = await taskQueue.addTask(
      type,
      data,
      parseInt(_id_code),
      api,
      user,
    );
    if (response && result) response(result);
    if (type == 'editTournamentName') settournament_name(data.tournament_name);
  };

  useEffect(() => {
    const fetchData = async () => {
      const user = await db.users.toCollection().first();
      if (!user) {
        navigate('/login');
        return;
      }
      console.log(user);
      setUser(user);
    };
    fetchData();
  }, [navigate]);

  const handleBackApp = () => {
    navigate('/');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const data = [];
        // const tournament = await getTournamentById(id);
        // data.push(tournament);
        // settournament_name(data[0].tournament_name);
        // data[0].stages_cup_id = JSON.parse(data[0].stages_cup_id);
        // if (data[0].stages_cup_id.length > 1) {
        //   console.log(data[0].stages_cup_id.length);
        // }

        const data = await getTournamentsByStagesCupId(id);
        const _currentTournament = data.find(
          tournament => tournament.id === id,
        );
        if (_currentTournament) {
          const { first_fish, big_fish_day, current_count_cup, count_cups } =
            _currentTournament;
          set_currentTournament({
            first_fish,
            big_fish_day,
            current_count_cup,
            count_cups,
          });
          console.log('count_fish:', first_fish);
          console.log('big_fish_day:', big_fish_day);
          console.log('current_count_cup:', current_count_cup);
        }
        settournament_name(data[0].tournament_name);
        setTournament(data);
      } catch (error) {
        console.error('Ошибка загрузки данных турнира:', error);
      }
      try {
        const dataRole = await getManagementJudgesByTournamentId(id);
        console.log('dataRole', dataRole);
        setTournamentJudges(dataRole);
      } catch (error) {
        console.error('Ошибка getManagementJudgesByTournamentId:', error);
      }
    };
    setid_code(id + '' + Date.now());
    fetchData();
  }, [id]);

  useEffect(() => {
    if (user && tournamentJudges.length > 0) {
      const roles = tournamentJudges
        .filter(item => item.user_id === user.id)
        .map(item => item.role);
      console.log('roles', roles);
      setUserRoles(roles);
    }
  }, [user, tournamentJudges]);

  const hasRoles = rolesToCheck => {
    if (!Array.isArray(rolesToCheck)) {
      rolesToCheck = [rolesToCheck];
    }
    if (!userRoles || userRoles.length === 0) {
      return false;
    }
    return rolesToCheck.some(role => userRoles.includes(role));
  };

  if (!tournament || !user) {
    return <div>Loading...</div>;
  }
  console.log('currentTournament', currentTournament);
  return (
    <>
      <Box
        sx={{
          marginTop: 3,
          marginLeft: 1,
          marginRight: 1,
        }}
      >
        <IconButton
          edge='start'
          color='inherit'
          onClick={handleBackApp}
          size='small'
        >
          <ArrowBackIcon /> назад
        </IconButton>
      </Box>
      <Box sx={{ marginTop: 1, marginLeft: 2, marginRight: 2 }}>
        <h2>
          {tournament_name}{' '}
          {currentTournament.count_cups > 1 ? (
            <> {currentTournament.current_count_cup} этап</>
          ) : (
            <></>
          )}
        </h2>
        <Grid
          container
          pt={3}
          pl={1}
          pr={1}
          justifyContent='space-between'
          spacing={1}
        >
          <Grid item xs={12} sm={6}>
            <BlockGeneralInformation
              sendTaskQueue={sendTaskQueue}
              data={tournament}
              isEdit={hasRoles([
                ROLE.ORGANIZER,
                ROLE.CHIEF_JUDGE,
                ROLE.CHIEF_SECRETARY,
              ])}
              tournamentId={id}
              isAddNewStage={hasRoles([ROLE.ORGANIZER])}
              idUser={user.id}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {hasRoles([
              ROLE.ORGANIZER,
              ROLE.CHIEF_JUDGE,
              ROLE.ASSISTANT_CHIEF_JUDGE,
              ROLE.ASSISTANT_CHIEF_SECRETARY,
              ROLE.CHIEF_SECRETARY,
            ]) ? (
              <BlockAdditionalNominations
                sendTaskQueue={sendTaskQueue}
                first_fish={currentTournament.first_fish}
                big_fish_day={currentTournament.big_fish_day}
                tournamentId={id}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {user ? (
              <BlockManagementJudges
                tournamentId={id}
                currentUserId={user.id}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <BlockSocialNetworks />
          </Grid>
          <Grid item xs={12} sm={6}>
            <BlockSProtocolConstructor />
          </Grid>
          <Grid item xs={12} sm={6}>
            <BlockDrawSettings />
          </Grid>
        </Grid>
        <Grid
          container
          pt={4}
          pl={1}
          pr={1}
          justifyContent='space-between'
          spacing={1}
        >
          {' '}
          <div>группа ведение турнира</div>
          <Grid item xs={12} sm={6}>
            <BlockDraw />
          </Grid>
          <Grid item xs={12} sm={6}>
            <BlockRegistrations />
          </Grid>
          <Grid item xs={12} sm={6}>
            <BlockWeighing />
          </Grid>
          <Grid item xs={12} sm={6}>
            <BlockEditingWeighting />
          </Grid>
        </Grid>
        <Grid
          container
          pt={4}
          pl={1}
          pr={1}
          justifyContent='space-between'
          spacing={1}
        >
          <div>группа документации</div>
          <Grid item xs={12} sm={6}>
            <BlockPrintingDocuments />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Tournament;
