import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { TextField } from '@mui/material';

const LengthTournament = forwardRef((props, ref) => {
  const { value, onChange } = props;
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('В часах (48,72,96 и тд)');

  useImperativeHandle(ref, () => ({
    validate: () => {
      if (value < 1 || value > 255) {
        setError(true);
        setHelperText(
          'Продолжительность турнира должна быть от 1 до 255 часов',
        );
        return false;
      } else {
        setError(false);
        setHelperText('В часах (48,72,96 и тд)');
        return true;
      }
    },
  }));

  const handleInputChange = event => {
    const newValue = event.target.value;
    if (newValue >= 1 && newValue <= 255) {
      setError(false);
      setHelperText('В часах (48,72,96 и тд)');
    }
    onChange(newValue);
  };

  return (
    <TextField
      id='outlined-number'
      label='Продолжительность'
      type='text'
      inputMode='numeric'
      helperText={helperText}
      onChange={handleInputChange}
      value={value}
      sx={{ width: '100%' }}
      error={error}
      fullWidth
      margin='dense'
      InputLabelProps={{ shrink: true }}
    />
  );
});

export default LengthTournament;
