import db from '../../db';

export const getAllTournaments = async () => {
  try {
    const tournaments = await db.tournaments.toArray();
    tournaments.sort((a, b) => {
      const [dayA, monthA, yearA] = a.start_date.split('.');
      const [dayB, monthB, yearB] = b.start_date.split('.');
      const dateA = new Date(`${yearA}-${monthA}-${dayA}`);
      const dateB = new Date(`${yearB}-${monthB}-${dayB}`);
      return dateA - dateB;
    });
    return tournaments;
  } catch (error) {
    //  console.error('Ошибка getAllTournaments:', error);
  }
};

export const getAllArchiveTournaments = async () => {
  try {
    const tournaments = await db.archive.toArray();
    tournaments.sort((a, b) => {
      const [dayA, monthA, yearA] = a.start_date.split('.');
      const [dayB, monthB, yearB] = b.start_date.split('.');
      const dateA = new Date(`${yearA}-${monthA}-${dayA}`);
      const dateB = new Date(`${yearB}-${monthB}-${dayB}`);
      return dateA - dateB;
    });

    return tournaments;
  } catch (error) {
    // console.error('Ошибка getAllArchiveTournaments:', error);
  }
};
export const getTournamentById = async id => {
  try {
    let tournament = await db.tournaments.where('id').equals(id).first();
    if (!tournament) {
      tournament = await db.archive.where('id').equals(id).first();
    }
    return tournament;
  } catch (error) {
    return [];
  }
};
export const getTournamentsByStagesCupId = async tournamentId => {
  try {
    let tournament = await db.tournaments
      .where('id')
      .equals(tournamentId)
      .first();

    if (!tournament) {
      tournament = await db.archive.where('id').equals(tournamentId).first();
    }
    if (!tournament) {
      return [];
    }

    const stagesCupArray = JSON.parse(tournament.stages_cup_id);
    const ids = stagesCupArray.map(stage => Object.values(stage)).flat();
    let tournaments = await db.tournaments.where('id').anyOf(ids).toArray();
    let archivedTournaments = await db.archive.where('id').anyOf(ids).toArray();
    const allTournaments = [...tournaments, ...archivedTournaments];
    const uniqueTournaments = Array.from(
      new Set(allTournaments.map(t => t.id)),
    ).map(id => allTournaments.find(t => t.id === id));

    return uniqueTournaments;
  } catch (error) {
    console.error('Ошибка при получении турниров:', error);
    return [];
  }
};

export const getTournamentAdditionalNominations = async id => {
  console.log(id);
  try {
    let tournament = await db.tournaments.where('id').equals(id).first();
    console.log(tournament.big_fish_day);
    return {
      bigFishDay: tournament.big_fish_day > 0 ? true : false,
      firstFish: tournament.first_fish > 0 ? true : false,
    };
  } catch (error) {
    console.error(
      'Ошибка при получении данных о дополнительных номинациях:',
      error,
    );
    throw new Error('Ошибка при получении данных о дополнительных номинациях');
  }
};
export const updateTournamentAdditionalNominations = async data => {
  try {
    await db.tournaments.update(data.tournamentId, {
      first_fish: data.first_fish,
      big_fish_day: data.big_fish_day,
    });

    console.log(
      `Данные о дополнительных номинациях для турнира ${data.tournamentId} успешно обновлены.`,
    );
  } catch (error) {
    console.error(
      'Ошибка при обновлении данных о дополнительных номинациях:',
      error.message,
    );
    throw new Error('Ошибка при обновлении данных о дополнительных номинациях');
  }
};
export const updateTournamentAdditionalNominations2 = async (
  data,
  stages_cup_id,
) => {
  try {
    console.log(data, stages_cup_id);

    let tournaments = await db.tournaments
      .where('stages_cup_id')
      .equals(stages_cup_id)
      .toArray();
    // Найти все турниры в таблице archive с заданным stages_cup_id
    let archivedTournaments = await db.archive
      .where('stages_cup_id')
      .equals(stages_cup_id)
      .toArray();

    // Объединить результаты
    const allTournaments = [...tournaments, ...archivedTournaments];

    // Обновить значение указанного поля для всех найденных турниров
    const updatePromises = allTournaments.map(tournament =>
      db.tournaments
        .update(tournament.id, {
          first_fish: data.first_fish,
          big_fish_day: data.big_fish_day,
        })
        .catch(error => {
          console.error(
            `Ошибка при обновлении данных для поля для турнира с ID ${tournament.id}:`,
            error.message,
          );
        }),
    );

    await Promise.all(updatePromises);

    // await db.tournaments.update(data.tournamentId, {
    //   first_fish: data.first_fish,
    //   big_fish_day: data.big_fish_day,
    // });

    // console.log(
    //   `Данные о дополнительных номинациях для турнира ${data.tournamentId} успешно обновлены.`,
    // );
  } catch (error) {
    console.error(
      'Ошибка при обновлении данных о дополнительных номинациях:',
      error.message,
    );
    throw new Error('Ошибка при обновлении данных о дополнительных номинациях');
  }
};
export const updateTournamentField = async data => {
  try {
    const fieldName = data.field_name;
    const fieldValue = data.field_value;

    // Найти все турниры в таблице tournaments с заданным stages_cup_id
    let tournaments = await db.tournaments
      .where('stages_cup_id')
      .equals(data.stages_cup_id)
      .toArray();

    // Найти все турниры в таблице archive с заданным stages_cup_id
    let archivedTournaments = await db.archive
      .where('stages_cup_id')
      .equals(data.stages_cup_id)
      .toArray();

    // Объединить результаты
    const allTournaments = [...tournaments, ...archivedTournaments];
    console.log(data);
    console.log(allTournaments);
    console.log(`${fieldName}: ${fieldValue}`);

    // Обновить значение указанного поля для всех найденных турниров
    const updatePromises = allTournaments.map(tournament =>
      db.tournaments
        .update(tournament.id, {
          [fieldName]: fieldValue,
        })
        .catch(error => {
          console.error(
            `Ошибка при обновлении данных для поля ${fieldName} для турнира с ID ${tournament.id}:`,
            error.message,
          );
        }),
    );

    await Promise.all(updatePromises);

    console.log(
      `Данные успешно обновлены для поля ${fieldName} для всех связанных турниров.`,
    );
  } catch (error) {
    console.error(`Ошибка при обновлении данных  `, error.message);
    throw new Error(`Ошибка при обновлении данных   `);
  }
};
export const updateTournamentFieldById = async data => {
  try {
    const fieldName = data.field_name;
    const fieldValue = data.field_value;

    let tournaments = await db.tournaments
      .where('id')
      .equals(data.id)
      .toArray();

    let archivedTournaments = await db.archive
      .where('id')
      .equals(data.id)
      .toArray();

    // Объединить результаты
    const allTournaments = [...tournaments, ...archivedTournaments];
    console.log(data);
    console.log(allTournaments);
    console.log(`${fieldName}: ${fieldValue}`);

    // Обновить значение указанного поля для всех найденных турниров
    const updatePromises = allTournaments.map(tournament =>
      db.tournaments
        .update(tournament.id, {
          [fieldName]: fieldValue,
        })
        .catch(error => {
          console.error(
            `Ошибка при обновлении данных для поля ${fieldName} для турнира с ID ${tournament.id}:`,
            error.message,
          );
        }),
    );

    await Promise.all(updatePromises);

    console.log(
      `Данные успешно обновлены для поля ${fieldName} для всех связанных турниров.`,
    );
  } catch (error) {
    console.error(`Ошибка при обновлении данных  `, error.message);
    throw new Error(`Ошибка при обновлении данных   `);
  }
};

export const updateStagesCupId = async stagesCupId => {
  const tables = [db.tournaments, db.archive];

  const stagesCupIdArray = JSON.parse(stagesCupId);
  const idsToUpdate = stagesCupIdArray.map(stage => Object.values(stage)[0]);

  for (const table of tables) {
    for (const id of idsToUpdate) {
      const item = await table.get(id);

      if (item) {
        const updatedStagesCupId = JSON.stringify(stagesCupIdArray);
        await table.update(id, { stages_cup_id: updatedStagesCupId });
      }
    }
  }
};
