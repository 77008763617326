import React, { useState } from 'react';
import { TextField, Button, Container, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import UserProfile from '../db/UserProfile';
import { setIdUser, setIdCode } from '../redux/actions';
import { useDispatch } from 'react-redux';
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://protocarp.ru/api/loginuser.php',
        {
          email,
          password,
        },
      );

      if (response.data.success) {
        setError('');
        const { id, name, token, rank, contacts, int_number } =
          response.data.user;
        dispatch(setIdUser(id));
        dispatch(setIdCode(id));
        await new Promise((resolve, reject) => {
          UserProfile.updateUser(id, {
            name,
            token,
            rank,
            contacts,
            int_number,
          })
            .then(() => resolve())
            .catch(error => reject(error));
        });

        navigate('/');
      } else {
        if (response.data.error === 'User not found') {
          setError('Неверный email.');
        } else {
          setError('Неверный пароль.');
        }
      }
    } catch (error) {
      setError('Произошла ошибка. Пожалуйста, попробуйте снова.');
    }
  };

  return (
    <Container maxWidth='sm'>
      <Box sx={{ mt: 10 }}>
        <Typography variant='h5' component='h1' gutterBottom>
          Авторизация
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label='Email'
            fullWidth
            margin='normal'
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            label='Пароль'
            type='password'
            fullWidth
            margin='normal'
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          {error && (
            <Typography color='error' variant='body2'>
              {error}
            </Typography>
          )}
          <Box mt={2}>
            <Button type='submit' variant='contained' color='primary' fullWidth>
              Войти
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default Login;
