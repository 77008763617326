import React from 'react';
import  Header from "../header/Header"
import { useSelector } from 'react-redux';
const Layout = ({ children }) => {
     const isOnline = useSelector((state) => state.isOnline);

  return (
    <div>
      <Header />
      <div style={{ marginTop: isOnline ? '114px' : '164px' }}>
        {children}
      </div>
    </div>
  );
};

export default Layout;
