import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';
import { SpeedDial } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PhishingIcon from '@mui/icons-material/Phishing';
import QrCodeIcon from '@mui/icons-material/QrCode';
import Person2Icon from '@mui/icons-material/Person2';
import QRCodeModal from '../qrCode/QRCodeModal'; // Импортируем компонент для QR-кода
import { getUser } from '../db/User/getUser';
import { useNavigate } from 'react-router-dom';

const MainMenuButton = () => {
  const navigate = useNavigate();

  const [openModalMenu, setOpenModalMenu] = useState(false);
  const [openQrModal, setOpenQrModal] = useState(false);
  const [qrDataUser, setQrDataUser] = useState({});
  const [int_number, setint_number] = useState('');

  const handleToggleModal = () => {
    setOpenModalMenu(!openModalMenu);
  };

  const handleCloseModal = () => {
    setOpenModalMenu(false);
  };

  const handleCloseQrModal = () => {
    setOpenQrModal(false);
  };

  const handleQrCode = () => {
    setOpenModalMenu(false); // Закрываем текущее модальное окно с меню
    setOpenQrModal(true); // Открываем модальное окно с QR-кодом
  };

  const handleCarpFishing = () => {
    setOpenModalMenu(false);
    handleCloseModal();
    navigate('/create-tournament');
  };

  const handleBottomFishing = () => {
    console.log('Ловля донной удочкой');
    handleCloseModal();
  };

  const handlePersone = () => {
    console.log('Ваши данные');
    handleCloseModal();
  };

  const buttonStyle = {
    width: '290px',
    mb: 2,
  };

  useEffect(() => {
    const fetchQrDataUser = async () => {
      try {
        const data = await getUser();
        setint_number(data[0].int_number);
        setQrDataUser({
          id: data[0].id,
          name: data[0].name,
          rank: data[0].rank,
          contacts: data[0].contacts,
        });
      } catch (error) {
        console.error('Ошибка загрузки данных:', error);
      }
    };
    fetchQrDataUser();
  }, []);

  // const qrDataUser = {
  //   sector: 'A1',
  //   weights: [1.5, 2.3, 0.9],
  // };

  return (
    <>
      <SpeedDial
        ariaLabel='SpeedDial example'
        sx={{ position: 'fixed', bottom: 36, right: 36 }}
        icon={<MenuIcon />}
        onClick={handleToggleModal}
        open={openModalMenu}
      ></SpeedDial>

      <Dialog open={openModalMenu} onClose={handleCloseModal}>
        <DialogContent>
          <Typography variant='h6' gutterBottom>
            Создать турнир:
          </Typography>
          <div>
            <Button
              onClick={handleCarpFishing}
              variant='contained'
              startIcon={<PhishingIcon />}
              sx={buttonStyle}
              color='success'
            >
              Ловля карпа
            </Button>
          </div>
          <div>
            <Button
              onClick={handleBottomFishing}
              variant='contained'
              startIcon={<PhishingIcon />}
              sx={buttonStyle}
              color='success'
            >
              Ловля донной удочкой
            </Button>
          </div>
          <Typography variant='h6' gutterBottom>
            Личный кабинет:
          </Typography>
          <div>
            <Button
              onClick={handlePersone}
              variant='contained'
              startIcon={<Person2Icon />}
              sx={buttonStyle}
              color='secondary'
            >
              Ваши данные
            </Button>
          </div>
          <Typography variant='h6' gutterBottom>
            Код судьи:
          </Typography>
          <div>
            <Button
              onClick={handleQrCode}
              variant='contained'
              startIcon={<QrCodeIcon />}
              sx={buttonStyle}
            >
              Показать
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color='secondary'>
            Отмена
          </Button>
        </DialogActions>
      </Dialog>

      <QRCodeModal
        int_number={int_number}
        open={openQrModal}
        onClose={handleCloseQrModal}
        data={qrDataUser}
      />
    </>
  );
};

export default MainMenuButton;
