import React, { useState, useRef, useEffect } from 'react';
import { Grid, Box, Button } from '@mui/material';
import NameTournamentInput from './NameTournamentInput';
import NameWaterInput from './NameWaterInput';
import LengthTournament from './LengthTournament';
import StartDateTournament from './StartDateTournament';
import EndDateTournament from './EndDateTournament';
import CountCups from './CountCups';
import TournamentType from './TournamentType';
import TournamentNature from './TournamentNature';
import TournamentDraw from './TournamentDraw';
import NominationsSelect from './NominationsSelect';
import DopNominationsSelect from './DopNominationsSelect';
import { TOURNAMENT_STATUS } from '../../settings';
import TaskQueue from '../../taskQueue/TaskQueue';
import { useNavigate } from 'react-router-dom';

import db from '../../db';
import dayjs from 'dayjs';
import {
  WEIGH_FISH,
  COUNT_FISH,
  COUNT_BIG_FISH,
  BIG_FISH_DAY,
  FIRST_FISH,
} from '../../settings';

const MainInfo = () => {
  const taskQueue = new TaskQueue(db);
  let isValid = true;
  const navigate = useNavigate();
  const [tournament_name, seTournamentName] = useState('');
  const tournamentNameRef = useRef();

  const [water_body, setWaterName] = useState('');
  const waterNameRef = useRef();

  const [duration, setLengthTournament] = useState(72);
  const lengthTournamentRef = useRef();

  const [start_date, setStartDate] = useState(null);
  const startDateRef = useRef();

  const [end_date, setEndDate] = useState(null);
  const endDateRef = useRef();

  const [count_cups, setCountCups] = useState(1);
  const countCupsRef = useRef();

  const [competition_type, setTournamentType] = useState('');
  const tournamentTypeRef = useRef();
  const status_cup = TOURNAMENT_STATUS.SET;
  const [competition_format, setTournamentNature] = useState('');
  const tournamentNatureRef = useRef();

  const [tournament_draw, setTournamentDrawe] = useState('');
  const tournamentDrawRef = useRef();
  const [id_code, setid_code] = useState(null);
  const [user, set_user_id] = useState(null);
  const [nominations, setNominations] = useState([]);
  const nominationsRef = useRef();
  const [dopNominations, setDopNominations] = useState([]);

  useEffect(() => {
    const fetchUser = async () => {
      const user = await db.users.toCollection().first();
      if (!user) {
        console.error('Пользователь не найден в базе данных');
        navigate('/login');
        return;
      }
      const { id, token } = user;
      setid_code(id + '' + Date.now());
      set_user_id(user);
    };

    fetchUser();
  }, []);

  const validatetournament = async () => {
    try {
      // Получаем пользователя из базы данных
      const user = await db.users.toCollection().first();

      // Проверяем наличие пользователя
      if (!user) {
        console.error('Пользователь не найден в базе данных');
        navigate('/login');
        return;
      }

      // Извлекаем id и token из пользователя

      // Дальнейшая логика валидации
      let isValid = true;
      if (tournamentNameRef.current && !tournamentNameRef.current.validate())
        isValid = false;
      if (waterNameRef.current && !waterNameRef.current.validate())
        isValid = false;
      if (
        lengthTournamentRef.current &&
        !lengthTournamentRef.current.validate()
      )
        isValid = false;
      if (startDateRef.current && !startDateRef.current.validate())
        isValid = false;
      if (endDateRef.current && !endDateRef.current.validate()) isValid = false;
      if (countCupsRef.current && !countCupsRef.current.validate())
        isValid = false;
      if (tournamentTypeRef.current && !tournamentTypeRef.current.validate())
        isValid = false;
      if (
        tournamentNatureRef.current &&
        !tournamentNatureRef.current.validate()
      )
        isValid = false;
      if (tournamentDrawRef.current && !tournamentDrawRef.current.validate())
        isValid = false;
      if (nominationsRef.current && !nominationsRef.current.validate())
        isValid = false;

      console.log('isValid', isValid);
      console.log('id_code', id_code);
      const end_date_formate = end_date =>
        end_date ? dayjs(end_date).format('DD.MM.YYYY') : '';
      const stages_cup_id = '[{"id1":"' + id_code + '"}]';
      const start_date_formate = start_date =>
        start_date ? dayjs(start_date).format('DD.MM.YYYY') : '';

      if (isValid && id_code) {
        const tournamentData = {
          id: id_code,
          tournament_name,
          status_cup,
          water_body,
          competition_type,
          competition_format,
          start_date: start_date_formate(start_date),
          end_date: end_date_formate(end_date),
          duration,
          count_cups,
          current_count_cup: 1,
          tournament_draw,
          weigh_fish: nominations.includes(WEIGH_FISH) ? 1 : 0,
          count_fish: nominations.includes(COUNT_FISH) ? 1 : 0,
          count_big_fish: nominations.includes(COUNT_BIG_FISH) ? 1 : 0,
          int_count_big_fish: 0,
          big_fish_day: dopNominations.includes(BIG_FISH_DAY) ? 1 : 0,
          first_fish: dopNominations.includes(FIRST_FISH) ? 1 : 0,
          id_code,
          stages_cup_id,
        };
        // Ждем завершения добавления задачи
        const result = await taskQueue.addTask(
          'createTournament',
          tournamentData,
          id_code,
          'addTournament',
          user,
        );
        // Переход на страницу турнира
        if (result) {
          navigate('/tournament/' + id_code);
        }
      }
    } catch (error) {
      console.error('Ошибка при выполнении функции validatetournament:', error);
    }
  };

  return (
    <Box
      sx={{ marginTop: 2, marginLeft: 2, marginRight: 2, overflow: 'hidden' }}
    >
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            marginTop: 1,
          }}
        >
          <NameTournamentInput
            value={tournament_name}
            onChange={seTournamentName}
            ref={tournamentNameRef}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            marginTop: 1,
          }}
        >
          <NameWaterInput
            value={water_body}
            onChange={setWaterName}
            ref={waterNameRef}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          sx={{
            marginTop: 1,
          }}
        >
          <LengthTournament
            value={duration}
            onChange={setLengthTournament}
            ref={lengthTournamentRef}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          sx={{
            marginTop: 1,
          }}
        >
          <StartDateTournament
            value={start_date}
            onChange={setStartDate}
            ref={startDateRef}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          sx={{
            marginTop: 1,
          }}
        >
          <EndDateTournament
            value={end_date}
            onChange={setEndDate}
            ref={endDateRef}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          sx={{
            marginTop: 1,
          }}
        >
          <CountCups
            value={count_cups}
            onChange={setCountCups}
            ref={countCupsRef}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            marginTop: 1,
          }}
        >
          <TournamentType
            value={competition_type}
            onChange={setTournamentType}
            ref={tournamentTypeRef}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            marginTop: 1,
          }}
        >
          <TournamentNature
            value={competition_format}
            onChange={setTournamentNature}
            ref={tournamentNatureRef}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            marginTop: 1,
          }}
        >
          <NominationsSelect
            nominations={nominations}
            onChange={setNominations}
            ref={nominationsRef}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            marginTop: 1,
          }}
        >
          <DopNominationsSelect
            nominations={dopNominations}
            onChange={setDopNominations}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            marginTop: 1,
          }}
        >
          <TournamentDraw
            value={tournament_draw}
            onChange={setTournamentDrawe}
            ref={tournamentDrawRef}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          sx={{ textAlign: 'right', marginBottom: '50px', marginTop: '20px' }}
        >
          <Button
            variant='contained'
            onClick={validatetournament}
            sx={{ marginBottom: '50px' }}
          >
            Создать
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MainInfo;
