// src/saveTournaments.js
import db from '../db';

export const saveTournaments = async tournaments => {
  try {
    await db.tournaments.clear();
    await db.tournaments.bulkAdd(tournaments);
  } catch (error) {
    console.error('Ошибка при сохранении турниров:', error);
  }
};

export const saveTournamentsArchive = async tournaments => {
  try {
    await db.archive.clear();
    await db.archive.bulkAdd(tournaments);
  } catch (error) {
    console.error('Ошибка при сохранении турниров:', error);
  }
};

export const saveTournamentsNotClean = async tournaments => {
  try {
    if (!Array.isArray(tournaments)) {
      tournaments = [tournaments];
    }
    await db.tournaments.bulkAdd(tournaments);
  } catch (error) {
    console.error('Ошибка при сохранении турниров:', error);
  }
};
