import Dexie from 'dexie';

const db = new Dexie('protofishDB');

db.version(1).stores({
  users: '++id, name, token, rank, contacts, int_number',
  tournaments:
    '++id, current_count_cup, tournament_name, status_cup, water_body, competition_type, competition_format, start_date, duration, end_date, count_cups, tournament_draw, weigh_fish, count_fish, count_big_fish, big_fish_day, first_fish, int_count_big_fish, time_code, stages_cup_id',
  archive:
    '++id, current_count_cup, tournament_name, status_cup, water_body, competition_type, competition_format, start_date, duration, end_date, count_cups, tournament_draw, weigh_fish, count_fish, count_big_fish, big_fish_day, first_fish, int_count_big_fish, time_code, stages_cup_id',
  tasks: '++id, id_code, type, data, api, status',
  managementjudges: '++id,  user_id, tournament_id, role, name, rank, contacts',
});

export default db;
