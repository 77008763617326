import React, { useEffect, useRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { TOURNAMENT_STATUS } from '../../settings';
import NameTournamentInput from './NameTournamentInput';
import TournamentNature from './TournamentNature';
import TournamentType from './TournamentType';
import LengthTournament from './LengthTournament';
import CountCups from './CountCups';
import NameWaterInput from './NameWaterInput';
import StartDateTournament from './StartDateTournament';
import EndDateTournament from './EndDateTournament';
import dayjs from 'dayjs';

const BlockGeneralInformation = ({
  sendTaskQueue,
  data,
  isEdit,
  tournamentId,
  isAddNewStage,
  idUser,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [tournament, setTournament] = useState(data);
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');

  const tournamentNameRef = useRef();
  const [tournament_name, settournament_name] = useState('');
  const competition_formatRef = useRef();
  const [competition_format, setcompetition_format] = useState('');
  const competition_typeRef = useRef();
  const [competition_type, setcompetition_type] = useState('');
  const durationRef = useRef();
  const [duration, setduration] = useState('');
  const count_cupsRef = useRef();
  const [count_cups, setcount_cups] = useState('');
  const water_bodyRef = useRef();

  const start_dateRef = useRef();
  const [start_date, setstart_date] = useState(null);
  const end_dateRef = useRef();
  const [end_date, setend_date] = useState(null);
  const [temp_data, set_temp_data] = useState(null);
  const [currentTournamentId, set_currentTournamentId] = useState(null);

  const [newStageData, setNewStageData] = useState({
    water_body_nsd: '',
    start_date_nsd: null,
    end_date_nsd: null,
    stage_number: null,
  });

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const TITTLE = {
    name: 'Название',
    format: 'Характер',
    status: 'Статус',
    duration: 'Продолжительность',
    count_cups: 'Количество этапов',
    water_body: 'Водоем',
    start_date: 'Дата начала',
    end_date: 'Дата финиша',
    create_stage: 'Создание этапа',
  };

  useEffect(() => {
    if (data.length < data[0].count_cups) setExpanded(true);
  }, []);

  useEffect(() => {
    settournament_name(tournament[0].tournament_name);
    setcompetition_format(tournament[0].competition_format);
    setcompetition_type(tournament[0].competition_type);
    setduration(tournament[0].duration);
    setcount_cups(tournament[0].count_cups);

    setstart_date(dayjs(tournament[0].start_date, 'DD.MM.YYYY'));
    setend_date(dayjs(tournament[0].end_date, 'DD.MM.YYYY'));
  }, [tournament]);

  const handleOpen = (title, data, tid) => {
    console.log(title, data, tid);
    set_currentTournamentId(tid);
    setDialogTitle(title);
    set_temp_data(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    set_temp_data(null);
    setDialogTitle('');
  };

  const handleSave = () => {
    if (dialogTitle === TITTLE.name && tournamentNameRef.current) {
      tournament[0].tournament_name = tournament_name;
      // (type, data, api, response = null)
      if (tournamentNameRef.current.validate()) {
        sendTaskQueue(
          'editTournamentName',
          {
            stages_cup_id: tournament[0].stages_cup_id,
            tournament_name: tournament_name,
            tournamentId,
          },
          'editTournamentName',
          null,
          null,
        );
        handleClose();
      }
    } else if (dialogTitle === TITTLE.format && competition_formatRef.current) {
      if (competition_formatRef.current.validate()) {
        tournament[0].competition_format = competition_format;
        sendTaskQueue(
          'editCompetitionFormat',
          {
            stages_cup_id: tournament[0].stages_cup_id,
            competition_format: competition_format,
            tournamentId,
          },
          'editCompetitionFormat',
          null,
          null,
        );
        handleClose();
      }
    } else if (dialogTitle === TITTLE.status && competition_typeRef.current) {
      if (competition_typeRef.current.validate()) {
        tournament[0].competition_type = competition_type;
        sendTaskQueue(
          'editCompetitionType',
          {
            stages_cup_id: tournament[0].stages_cup_id,
            competition_type: competition_type,
            tournamentId,
          },
          'editCompetitionType',
          null,
          null,
        );
        handleClose();
      }
    } else if (dialogTitle === TITTLE.duration && durationRef.current) {
      if (durationRef.current.validate()) {
        tournament[0].duration = duration;
        sendTaskQueue(
          'editCompetitionDuration',
          {
            stages_cup_id: tournament[0].stages_cup_id,
            duration: duration,
            tournamentId,
          },
          'editCompetitionDuration',
          null,
          null,
        );
        handleClose();
      }
    } else if (dialogTitle === TITTLE.count_cups && count_cupsRef.current) {
      if (count_cupsRef.current.validate()) {
        tournament[0].count_cups = count_cups;
        sendTaskQueue(
          'editCompetitionCountCups',
          {
            stages_cup_id: tournament[0].stages_cup_id,
            count_cups: count_cups,
            tournamentId,
          },
          'editCompetitionCountCups',
          null,
          null,
        );
        handleClose();
      }
    } else if (dialogTitle === TITTLE.water_body && water_bodyRef.current) {
      if (water_bodyRef.current.validate()) {
        const index = tournament.findIndex(t => t.id === currentTournamentId);
        if (index !== -1) {
          tournament[index].water_body = temp_data;
        }

        sendTaskQueue(
          'editCompetitionWaterBody',
          {
            water_body: temp_data,
            tournamentId: currentTournamentId,
          },
          'editCompetitionWaterBody',
          null,
          null,
        );
        handleClose();
      }
    } else if (dialogTitle === TITTLE.start_date && start_dateRef.current) {
      if (start_dateRef.current.validate()) {
        const index = tournament.findIndex(t => t.id === currentTournamentId);
        if (index !== -1) {
          tournament[index].start_date = dayjs(start_date, 'DD.MM.YYYY').format(
            'DD.MM.YYYY',
          );
        }

        sendTaskQueue(
          'editCompetitionStartDate',
          {
            start_date: tournament[index].start_date,
            tournamentId: currentTournamentId,
          },
          'editCompetitionStartDate',
          null,
          null,
        );
        handleClose();
      }
    } else if (dialogTitle === TITTLE.end_date && end_dateRef.current) {
      if (end_dateRef.current.validate()) {
        const index = tournament.findIndex(t => t.id === currentTournamentId);
        if (index !== -1) {
          tournament[index].end_date = dayjs(end_date, 'DD.MM.YYYY').format(
            'DD.MM.YYYY',
          );
        }
        sendTaskQueue(
          'editCompetitionEndDate',
          {
            end_date: tournament[index].end_date,
            tournamentId: currentTournamentId,
          },
          'editCompetitionEndDate',
          null,
          null,
        );

        handleClose();
      }
    } else if (dialogTitle === TITTLE.create_stage) {
      const { water_body_nsd, start_date_nsd, end_date_nsd, stage_number } =
        newStageData;
      console.log('tournament', tournament);
      console.log('newStageData', newStageData);
      if (water_body_nsd && start_date_nsd && end_date_nsd && stage_number) {
        const id_code = idUser + '' + Date.now();
        const _stages_cup_id = JSON.parse(tournament[0].stages_cup_id);
        _stages_cup_id.push({ ['id' + stage_number]: id_code });
        const stages_cup_id = JSON.stringify(_stages_cup_id);
        console.log('id_code', id_code);
        const data = {
          start_date: dayjs(start_date_nsd, 'DD.MM.YYYY').format('DD.MM.YYYY'),
          end_date: dayjs(end_date_nsd, 'DD.MM.YYYY').format('DD.MM.YYYY'),
          tournament_name,
          water_body: water_body_nsd,
          competition_type,
          competition_format,
          count_cups,
          duration,
          tournament_draw: tournament[0].tournament_draw,
          status_cup: TOURNAMENT_STATUS.SET,
          int_count_big_fish: tournament[0].int_count_big_fish,
          id: id_code,
          id_code,
          weigh_fish: tournament[0].weigh_fish,
          count_fish: tournament[0].count_fish,
          count_big_fish: tournament[0].count_big_fish,
          big_fish_day: tournament[0].big_fish_day,
          first_fish: tournament[0].first_fish,
          current_count_cup: stage_number,
          stages_cup_id,
        };
        sendTaskQueue(
          'createNewStage',
          {
            ...data,
          },
          'createNewStage',
          null,
          id_code,
        );
        tournament.push(data);
        const updatedTournaments = tournament.map(t => ({
          ...t,
          stages_cup_id: stages_cup_id,
        }));

        setTournament(updatedTournaments);
        console.log('updatedTournaments', updatedTournaments);
        handleClose();
      }
    } else {
      handleClose();
    }
  };

  const createStage = stageNumber => {
    setNewStageData({
      water_body_nsd: '',
      start_date_nsd: null,
      end_date_nsd: null,
      stage_number: stageNumber,
    });
    handleOpen(TITTLE.create_stage);
  };

  const constr_gi = (name, data, tid) => {
    return (
      <Box display='flex' justifyContent='space-between' alignItems='start'>
        <Typography variant='subtitle1' sx={{ fontWeight: 600 }}>
          {name}: <span style={{ fontWeight: 400 }}>{data}</span>
        </Typography>
        {isEdit &&
        tournament[0].status_cup !== TOURNAMENT_STATUS.WEIGHING &&
        tournament[0].status_cup !== TOURNAMENT_STATUS.FINISH &&
        tournament[0].status_cup !== TOURNAMENT_STATUS.DRAW ? (
          <Button
            color='error'
            size='small'
            onClick={() => handleOpen(name, data, tid)}
          >
            изменить
          </Button>
        ) : (
          <></>
        )}
      </Box>
    );
  };

  return (
    <Paper style={{ padding: 16, marginTop: 16 }}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
        >
          <div>
            <Typography variant='h6'>Общая информация</Typography>
          </div>
          <div>
            <Typography
              variant='caption'
              display='block'
              sx={{ color: 'gray' }}
            >
              Редактирование название, водоема, даты проведения,
              продолжительности, этапов и тд.
            </Typography>
          </div>
        </Box>
        <IconButton aria-label='toggle' size='small' onClick={toggleExpand}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      {expanded && (
        <>
          <div style={{ marginTop: 15 }}> </div>
          {constr_gi(TITTLE.name, tournament[0].tournament_name)}
          {constr_gi(TITTLE.format, tournament[0].competition_format)}
          {constr_gi(TITTLE.status, tournament[0].competition_type)}
          {constr_gi(TITTLE.duration, tournament[0].duration)}
          {constr_gi(TITTLE.count_cups, tournament[0].count_cups)}
          {Array.from({ length: tournament[0].count_cups }, (_, index) => {
            const currentStage = tournament.find(
              stage => stage.current_count_cup === index + 1,
            );
            return (
              <div key={index}>
                {tournament[0].count_cups === 1 ? (
                  <></>
                ) : (
                  <Typography
                    variant='subtitle1'
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      paddingTop: '10px',
                    }}
                  >
                    {currentStage ? (
                      <>{index + 1} этап</>
                    ) : isEdit && isAddNewStage ? (
                      <>
                        {index + 1} этап
                        <span variant='subtitle1' style={{ color: 'red' }}>
                          {' '}
                          отсутствует.
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                  </Typography>
                )}

                {currentStage ? (
                  <>
                    {constr_gi(
                      TITTLE.water_body,
                      currentStage.water_body,
                      currentStage.id,
                    )}
                    {constr_gi(
                      TITTLE.start_date,
                      currentStage.start_date,
                      currentStage.id,
                    )}
                    {constr_gi(
                      TITTLE.end_date,
                      currentStage.end_date,
                      currentStage.id,
                    )}
                  </>
                ) : (
                  <>
                    {isEdit && isAddNewStage ? (
                      <div style={{ textAlign: 'center' }}>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={() => createStage(index + 1)}
                        >
                          Создать
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            );
          })}
        </>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            minWidth: '400px',
            maxWidth: '100%',
            '@media (max-width: 600px)': {
              minWidth: '100%',
              width: '100%',
            },
          },
        }}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          {dialogTitle === TITTLE.name ? (
            <NameTournamentInput
              value={tournament_name}
              onChange={settournament_name}
              ref={tournamentNameRef}
            />
          ) : (
            <></>
          )}
          {dialogTitle === TITTLE.format ? (
            <TournamentNature
              value={competition_format}
              onChange={setcompetition_format}
              ref={competition_formatRef}
            />
          ) : (
            <></>
          )}
          {dialogTitle === TITTLE.status ? (
            <TournamentType
              value={competition_type}
              onChange={setcompetition_type}
              ref={competition_typeRef}
            />
          ) : (
            <></>
          )}
          {dialogTitle === TITTLE.duration ? (
            <LengthTournament
              value={duration}
              onChange={setduration}
              ref={durationRef}
            />
          ) : (
            <></>
          )}
          {dialogTitle === TITTLE.count_cups ? (
            <CountCups
              value={count_cups}
              onChange={setcount_cups}
              ref={count_cupsRef}
            />
          ) : (
            <></>
          )}
          {dialogTitle === TITTLE.water_body ? (
            <NameWaterInput
              value={temp_data}
              onChange={set_temp_data}
              ref={water_bodyRef}
            />
          ) : (
            <></>
          )}

          {dialogTitle === TITTLE.start_date ? (
            <StartDateTournament
              value={dayjs(temp_data, 'DD.MM.YYYY')}
              onChange={setstart_date}
              ref={start_dateRef}
            />
          ) : (
            <></>
          )}
          {dialogTitle === TITTLE.end_date ? (
            <EndDateTournament
              value={dayjs(temp_data, 'DD.MM.YYYY')}
              onChange={setend_date}
              ref={end_dateRef}
            />
          ) : (
            <></>
          )}
          {dialogTitle === TITTLE.create_stage ? (
            <>
              <Typography
                variant='caption'
                display='block'
                sx={{ color: 'gray', marginBottom: '10px' }}
              >
                Введите название и даты проведения нового этапа. Все основные
                настройки будут перенесены из текущего турнира. состав судейской
                коллегии настраивается позже.
              </Typography>
              <NameWaterInput
                value={newStageData.water_body_nsd}
                onChange={value =>
                  setNewStageData({ ...newStageData, water_body_nsd: value })
                }
              />
              <StartDateTournament
                value={newStageData.start_date_nsd}
                onChange={value =>
                  setNewStageData({ ...newStageData, start_date_nsd: value })
                }
              />
              <EndDateTournament
                value={newStageData.end_date_nsd}
                onChange={value =>
                  setNewStageData({ ...newStageData, end_date_nsd: value })
                }
              />
            </>
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Отмена
          </Button>
          <Button onClick={handleSave} color='primary'>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default BlockGeneralInformation;
