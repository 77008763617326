import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { BIG_FISH_DAY, FIRST_FISH } from '../../settings';

const BlockAdditionalNominations = ({
  sendTaskQueue,
  first_fish,
  big_fish_day,
  tournamentId,
}) => {
  console.log(first_fish, big_fish_day, tournamentId);
  const [bigFishChecked, setBigFishChecked] = useState(big_fish_day === 1);
  const [firstFishChecked, setFirstFishChecked] = useState(first_fish === 1);
  const [expanded, setExpanded] = useState(false);

  const sendResponse = async (
    updatedBigFishChecked,
    updatedFirstFishChecked,
  ) => {
    const taskData = {
      big_fish_day: updatedBigFishChecked ? 1 : 0,
      first_fish: updatedFirstFishChecked ? 1 : 0,
      tournamentId,
    };
    sendTaskQueue(
      'saveAdditionalNominations',
      taskData,
      'saveAdditionalNominations',
      null,
    );
  };

  const handleBigFishChange = async () => {
    const updatedBigFishChecked = !bigFishChecked;
    setBigFishChecked(updatedBigFishChecked);
    sendResponse(updatedBigFishChecked, firstFishChecked);
  };

  const handleFirstFishChange = async () => {
    const updatedFirstFishChecked = !firstFishChecked;
    setFirstFishChecked(updatedFirstFishChecked);
    sendResponse(bigFishChecked, updatedFirstFishChecked);
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Paper style={{ padding: 16, marginTop: 16 }}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
        >
          <div>
            <Typography variant='h6'>Дополнительные номинации</Typography>
          </div>
          <div>
            <Typography
              variant='caption'
              display='block'
              sx={{ color: 'gray' }}
            >
              Редактирование номинация БигФиш суток и первая пойманная рыба.
            </Typography>
          </div>
        </Box>

        <IconButton aria-label='toggle' size='small' onClick={toggleExpand}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>

      {expanded && (
        <>
          <FormControlLabel
            control={
              <Checkbox
                checked={bigFishChecked}
                onChange={handleBigFishChange}
                color='primary'
              />
            }
            label={BIG_FISH_DAY}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={firstFishChecked}
                onChange={handleFirstFishChange}
                color='primary'
              />
            }
            label={FIRST_FISH}
          />
        </>
      )}
    </Paper>
  );
};

export default BlockAdditionalNominations;
