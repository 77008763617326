import db from '../db';

export const cleanAndSaveJudges = async managementjudges => {
  try {
    await db.managementjudges.clear();
    await db.managementjudges.bulkAdd(managementjudges);
  } catch (error) {
    console.error('Ошибка при сохранении managementjudges:', error);
  }
};
export const saveJudges = async managementjudges => {
  try {
    if (!Array.isArray(managementjudges)) {
      managementjudges = [managementjudges];
    }
    await db.managementjudges.bulkAdd(managementjudges);
  } catch (error) {
    console.error('Ошибка при сохранении managementjudges:', error);
  }
};
export const getManagementJudgesByTournamentId = async tournamentId => {
  try {
    console.log('getManagementJudgesByTournamentId', tournamentId);
    const judges = await db.managementjudges
      .where({ tournament_id: parseInt(tournamentId) })
      .toArray();
    return judges;
  } catch (error) {
    console.error('Ошибка getManagementJudgesByTournamentId:', error);
  }
};

export const saveJudgeToDB = async tournamentId => {
  console.log('saveJudgeToDB', tournamentId);

  return true;
};
export const deleteAndFetchRemainingJudges = async (
  userId,
  tournamentId,
  role,
) => {
  try {
    await db.managementjudges
      .where({
        user_id: parseInt(userId),
        tournament_id: parseInt(tournamentId),
        role: role,
      })
      .delete();

    const remainingJudges = await db.managementjudges
      .where({ tournament_id: parseInt(tournamentId) })
      .toArray();

    return remainingJudges;
  } catch (error) {
    console.error('Error deleting judge and fetching remaining judges:', error);
    return [];
  }
};
