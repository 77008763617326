import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { TextField } from '@mui/material';

const NameTournamentInput = forwardRef(({ value, onChange }, ref) => {
  const [error, onError] = useState(false);
  const [helperText, serHelperText] = useState('');

  useImperativeHandle(ref, () => ({
    validate: () => {
      if (value.length < 4) {
        onError(true);
        serHelperText('Название должно быть не менее 4 символов.');
        return false;
      } else {
        onError(false);
        serHelperText('');
        return true;
      }
    },
  }));
  const handleInputChange = event => {
    if (value.length >= 4) {
      onError(false);
      serHelperText('');
    }
    onChange(event.target.value);
  };

  return (
    <TextField
      label='Название соревнования'
      sx={{ width: '100%' }}
      value={value}
      onChange={handleInputChange}
      error={error}
      helperText={helperText}
      fullWidth
      margin='dense'  
      InputLabelProps={{ shrink: true }}  
    />
  );
});
export default NameTournamentInput;
