import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Box, Alert } from '@mui/material';
import { VERSION } from '../settings';
import { useSelector, useDispatch } from 'react-redux';
import { setOnlineStatus } from '../redux/actions';
import db from '../db';

// Initialize IndexedDB with Dexie

const Header = () => {
  const isOnline = useSelector(state => state.isOnline);
  const dispatch = useDispatch();
  const [tasks, setTasks] = useState(0);

  useEffect(() => {
    dispatch(setOnlineStatus(navigator.onLine));

    const handleOnline = () => dispatch(setOnlineStatus(true));
    const handleOffline = () => dispatch(setOnlineStatus(false));
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    const fetchPendingTasks = async () => {
      try {
        const pendingTasks = await db.tasks
          .where('status')
          .equals('pending')
          .count();
        setTasks(pendingTasks);
      } catch (error) {
        console.error('Ошибка при получении количества задач:', error);
      }
    };

    // Fetch pending tasks initially
    fetchPendingTasks();

    // Set an interval to periodically update tasks count
    const intervalId = setInterval(fetchPendingTasks, 1000); // Adjust the interval as needed
    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  return (
    <AppBar
      position='fixed'
      style={{ backgroundColor: 'black', margin: 0, padding: 0 }}
    >
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: 'flex' }}>
          <Typography variant='h6' component='div'>
            <span style={{ color: 'white' }}>PROTO</span>
            <span style={{ color: isOnline ? 'green' : 'red' }}>CARP</span>
            <span style={{ color: 'gray', fontSize: '0.6em' }}>
              {' '}
              v {VERSION}
            </span>
          </Typography>
        </Box>
      </Toolbar>
      {!isOnline && (
        <Alert severity='warning' sx={{ borderRadius: 0 }}>
          Работа в оффлайн-режиме.
        </Alert>
      )}
      <Alert
        severity={tasks === 0 ? 'success' : 'info'}
        sx={{ borderRadius: 0 }}
      >
        {tasks === 0
          ? 'Очередь на отправку - 0.'
          : `Очередь на отправку - ${tasks}.`}
      </Alert>
    </AppBar>
  );
};

export default Header;
