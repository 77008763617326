import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux';

import Login from './login/Login';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import store from './redux/store';
import CreateTournament from './tournaments/CreateTournament';
import Layout from './layout/Layout';
import db from './db';
import TaskQueue from './taskQueue/TaskQueue';
import Tournament from './tournaments/Tournament';
const container = document.getElementById('root');
const root = createRoot(container);
const taskQueue = new TaskQueue(db);

setInterval(() => {
  taskQueue.processTasks();
}, 3000);
root.render(
  <Provider store={store}>
    <Router basename='/'>
      <Layout>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/' element={<App />} />
          <Route path='/create-tournament' element={<CreateTournament />} />
          <Route path='/tournament/:id' element={<Tournament />} />
        </Routes>
      </Layout>
    </Router>
  </Provider>,
);

serviceWorkerRegistration.register();
