import axios from 'axios';
import db from './db';

const apiClient = axios.create({
  baseURL: 'https://protocarp.ru/api/',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const fetchData = async (endpoint, data = {}, navigate) => {
  const user = await db.users.toCollection().first();
  if (!user) {
    console.error('Пользователь не найден в базе данных');
    navigate('/login');
    return;
  }

  const { id, token } = user;

  try {
    const response = await apiClient.post(endpoint, {
      id,
      token,
      ...data,
    });

    if (!response.data.success && response.data.error === 'The user is not logged in') {
      navigate('/login');
    }

    return response.data;
  } catch (error) {
    if(error.code==="ERR_NETWORK"){
      return error.code
    }else {
      throw error;
    }
 
  }
};
