import React, { useState, useImperativeHandle, forwardRef } from 'react';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from '@mui/material';
import { WEIGH_FISH, COUNT_FISH, COUNT_BIG_FISH } from '../../settings';
const NominationsSelect = forwardRef(({ nominations, onChange }, ref) => {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');

  useImperativeHandle(ref, () => ({
    validate: () => {
      if (nominations.length > 0) {
        setError(false);
        setHelperText('');
        return true;
      } else {
        setError(true);
        setHelperText('Выберите хотя бы одну номинацию');
        return false;
      }
    },
  }));

  const handleNominationsChange = event => {
    const { value } = event.target;
    const newNominations = nominations.includes(value)
      ? nominations.filter(nomination => nomination !== value)
      : [...nominations, value];
    if (newNominations.length > 0) {
      setError(false);
      setHelperText('');
    }
    onChange(newNominations);
  };

  return (
    <FormControl component='fieldset' error={error}>
      <FormLabel component='legend'>
        Номинации для определения победителей (можно выбрать несколько)
      </FormLabel>
      <FormControlLabel
        control={
          <Checkbox
            checked={nominations.includes(WEIGH_FISH)}
            onChange={handleNominationsChange}
            value={WEIGH_FISH}
          />
        }
        label='По общему весу'
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={nominations.includes(COUNT_BIG_FISH)}
            onChange={handleNominationsChange}
            value={COUNT_BIG_FISH}
          />
        }
        label='По крупным рыбам'
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={nominations.includes(COUNT_FISH)}
            onChange={handleNominationsChange}
            value={COUNT_FISH}
          />
        }
        label='По количеству рыб'
      />
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
});

export default NominationsSelect;
