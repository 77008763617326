import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import QrCodeIcon from '@mui/icons-material/QrCode';
import ScanQRCode from '../qrCode/ScanQRCode';
import { ROLE } from '../settings';
import db from '../db';
import TaskQueue from '../taskQueue/TaskQueue';
import { useNavigate } from 'react-router-dom';

const AddJudgeModal = ({
  open,
  handleClose,
  tournamentId,
  updateJudgesList,
}) => {
  const [showQRCodeScanner, setShowQRCodeScanner] = useState(false);
  const [code, setCode] = useState('');
  const [judgeData, setJudgeData] = useState(null);
  const [role, setRole] = useState('');
  const [error, setError] = useState('');
  const [errorJu, setErrorJu] = useState('');
  const [rolesBlocked, setRolesBlocked] = useState({
    CHIEF_JUDGE: false,
    JUDGE: false,
  });
  const taskQueue = new TaskQueue(db);
  const navigate = useNavigate();

  useEffect(() => {
    if (open) {
      setCode('');
      setJudgeData(null);
      setRole('');
      setErrorJu('');
      setError('');
      setShowQRCodeScanner(false);
      checkExistingJudges();
    }
  }, [open]);

  const checkExistingJudges = async () => {
    const judges = await db.managementjudges
      .where({ tournament_id: Number(tournamentId) })
      .toArray();
    const roles = { CHIEF_JUDGE: false, JUDGE: false };

    judges.forEach(judge => {
      if (judge.role === ROLE.CHIEF_JUDGE) roles.CHIEF_JUDGE = true;
      if (judge.role === ROLE.JUDGE) roles.JUDGE = true;
    });

    setRolesBlocked(roles);
  };

  const handleScanSuccess = decodedText => {
    console.log('QR code scanned:', decodedText);
    setShowQRCodeScanner(false);

    try {
      const parsedData = JSON.parse(decodedText);
      if (
        parsedData.contacts &&
        parsedData.name &&
        parsedData.rank &&
        parsedData.id
      ) {
        setJudgeData(parsedData);
        setError('');
        setErrorJu('');
      } else {
        setError('Код не распознан');
      }
    } catch (e) {
      setError('Код не распознан');
    }
  };

  const handleScanError = errorMessage => {
    console.error('QR code scan error:', errorMessage);
  };

  const handleCodeChange = event => {
    setCode(event.target.value);
  };

  const handleRoleChange = event => {
    setRole(event.target.value);
  };

  const handleCodeSubmit = async scannedCode => {
    const codeToUse = scannedCode || code;
    if (codeToUse.length > 4) {
      try {
        const response = await fetch(
          'https://protocarp.ru/api/searchJudgeId.php',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code: codeToUse }),
          },
        );

        if (response.ok) {
          const data = await response.json();
          console.log('User found:', data);
          if (data.success && data.judge.length > 0) {
            setJudgeData(data.judge[0]);
            setError('');
            setErrorJu('');
          } else {
            setJudgeData(null);
            setError('Пользователь не найден');
          }
        } else {
          setError('Ошибка поиска пользователя');
          console.error('Error finding user:', response.statusText);
        }
      } catch (error) {
        setError('Ошибка поиска пользователя');
        console.error('Error finding user:', error);
      }
    } else {
      setError('Код должен быть длинее 4 символов');
    }
  };

  const handleAddJudge = async () => {
    if (!role) {
      setErrorJu('Пожалуйста, выберите тип судьи');
      return;
    }

    const existingJudge = await db.managementjudges
      .where({ tournament_id: Number(tournamentId), role })
      .first();

    if (
      existingJudge &&
      (role === ROLE.CHIEF_JUDGE || role === ROLE.CHIEF_SECRETARY)
    ) {
      setErrorJu(`${role} уже добавлен в турнир`);
      return;
    }

    const user = await db.users.toCollection().first();

    if (!user) {
      console.error('Пользователь не найден в базе данных');
      navigate('/login');
      return;
    }

    const taskData = {
      user_id: judgeData.id,
      tournament_id: Number(tournamentId),
      role,
      name: judgeData.name,
      rank: judgeData.rank,
      contacts: judgeData.contacts,
    };

    const id_code = `${judgeData.id}${Math.floor(Date.now() / 1000)}`;
    const result = await taskQueue.addTask(
      'addJudges',
      taskData,
      id_code,
      'addJudges',
      user,
    );

    if (result) {
      updateJudgesList();
      handleClose();
    }

    console.log('Добавляем судью:', taskData);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='add-judge-modal-title'
      aria-describedby='add-judge-modal-description'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id='add-judge-modal-description'>
          Отсканируйте QR-код для добавления или укажите числовой код (только
          при наличии интернета).
        </Typography>
        <Button
          variant='contained'
          startIcon={<QrCodeIcon />}
          sx={{ mt: 2 }}
          onClick={() => setShowQRCodeScanner(!showQRCodeScanner)}
        >
          {showQRCodeScanner ? 'Закрыть сканер' : 'Сканировать QR-код камерой'}
        </Button>
        {showQRCodeScanner && (
          <ScanQRCode
            onScanSuccess={handleScanSuccess}
            onScanError={handleScanError}
          />
        )}
        <TextField
          label='Числовой код'
          value={code}
          onChange={handleCodeChange}
          fullWidth
          sx={{ mt: 2 }}
        />
        {error && (
          <Typography variant='body2' color='error' sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
        <Button
          variant='contained'
          color='primary'
          sx={{ mt: 2 }}
          onClick={() => handleCodeSubmit()}
        >
          Найти
        </Button>
        {judgeData && (
          <Box sx={{ mt: 2 }}>
            <Typography variant='body1'>ФИО: {judgeData.name}</Typography>
            <Typography variant='body1'>Разряд: {judgeData.rank}</Typography>
            <Typography variant='body1'>
              Контакты: {judgeData.contacts}
            </Typography>
            <TextField
              select
              label='Тип судьи'
              value={role}
              onChange={handleRoleChange}
              fullWidth
              sx={{ mt: 2 }}
            >
              {Object.keys(ROLE).map(
                key =>
                  ROLE[key] !== ROLE.ORGANIZER && (
                    <MenuItem
                      key={key}
                      value={ROLE[key]}
                      disabled={rolesBlocked[ROLE[key]]}
                    >
                      {ROLE[key]}
                    </MenuItem>
                  ),
              )}
            </TextField>
            {errorJu && (
              <Typography variant='body2' color='error' sx={{ mt: 2 }}>
                {errorJu}
              </Typography>
            )}
            <Button
              variant='contained'
              color='primary'
              sx={{ mt: 2 }}
              onClick={handleAddJudge}
            >
              Добавить в турнир
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default AddJudgeModal;
