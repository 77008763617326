import { saveTournamentsNotClean } from '../db/saveTournaments';
import { saveJudges } from '../db/managementjudges';
import { ROLE } from '../settings';
import {
  updateTournamentAdditionalNominations,
  updateTournamentFieldById,
  updateStagesCupId,
  updateTournamentField,
} from '../db/Tournaments/getAllTournaments';
class TaskQueue {
  constructor(db) {
    this.db = db;
  }

  async addTask(type, data, id_code, api, user) {
    console.log(type, data, id_code, api, user);
    await this.db.tasks.add({ id_code, type, data, api, status: 'pending' });
    console.log(data);
    if (api === 'addTournament') {
      await saveTournamentsNotClean(data);
      const managementjudges = {
        user_id: user.id,
        tournament_id: parseInt(id_code),
        role: ROLE.ORGANIZER,
        name: user.name,
        rank: user.rank,
        contacts: user.contacts,
      };

      await saveJudges(managementjudges);
    } else if (api === 'addJudges') {
      await saveJudges(data);
    } else if (api === 'saveAdditionalNominations') {
      await updateTournamentAdditionalNominations(data);
    } else if (api === 'editTournamentName') {
      const dataField = {
        stages_cup_id: data.stages_cup_id,
        field_name: 'tournament_name',
        field_value: data.tournament_name,
      };
      console.log(dataField);
      await updateTournamentField(dataField);
    } else if (api === 'createNewStage') {
      await saveTournamentsNotClean(data);
      console.log('id_code', id_code);
      const managementjudges = {
        user_id: user.id,
        tournament_id: id_code,
        role: ROLE.ORGANIZER,
        name: user.name,
        rank: user.rank,
        contacts: user.contacts,
      };

      console.log('managementjudges', managementjudges);
      await saveJudges(managementjudges);
      await updateStagesCupId(data.stages_cup_id);
    } else if (api === 'editCompetitionFormat') {
      const dataField = {
        stages_cup_id: data.stages_cup_id,
        field_name: 'competition_format',
        field_value: data.competition_format,
      };
      console.log(dataField);
      await updateTournamentField(dataField);
    } else if (api === 'editCompetitionType') {
      const dataField = {
        stages_cup_id: data.stages_cup_id,
        field_name: 'competition_type',
        field_value: data.competition_type,
      };
      console.log(dataField);
      await updateTournamentField(dataField);
    } else if (api === 'editCompetitionDuration') {
      const dataField = {
        stages_cup_id: data.stages_cup_id,
        field_name: 'duration',
        field_value: data.duration,
      };
      console.log(dataField);
      await updateTournamentField(dataField);
    } else if (api === 'editCompetitionCountCups') {
      const dataField = {
        stages_cup_id: data.stages_cup_id,
        field_name: 'count_cups',
        field_value: data.count_cups,
      };
      console.log(dataField);
      await updateTournamentField(dataField);
    } else if (api === 'editCompetitionWaterBody') {
      const dataField = {
        id: data.tournamentId,
        field_name: 'water_body',
        field_value: data.water_body,
      };
      console.log(dataField);
      await updateTournamentFieldById(dataField);
    } else if (api === 'editCompetitionStartDate') {
      const dataField = {
        id: data.tournamentId,
        field_name: 'start_date',
        field_value: data.start_date,
      };

      console.log(dataField);
      await updateTournamentFieldById(dataField);
    } else if (api === 'editCompetitionEndDate') {
      const dataField = {
        id: data.tournamentId,
        field_name: 'end_date',
        field_value: data.end_date,
      };

      console.log(dataField);
      await updateTournamentFieldById(dataField);
    }

    await this.processTasks();
    return true;
  }

  async processTasks() {
    const tasks = await this.db.tasks.where({ status: 'pending' }).toArray();
    for (const task of tasks) {
      try {
        await this.sendTaskToServer(task);
        await this.db.tasks.delete(task.id);
      } catch (error) {
        //  console.error('Failed to process task', task, error);
      }
    }
  }

  async sendTaskToServer(task) {
    if (!navigator.onLine) {
      throw new Error('No internet connection');
    }

    const user = await this.db.users.toArray().then(users => users[0]);

    if (!user) {
      throw new Error('No user found in database');
    }

    task.data.id = user.id;
    task.data.token = user.token;

    const response = await fetch(
      'https://protocarp.ru/api/' + task.api + '.php',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(task.data),
      },
    );

    if (!response.ok) {
      throw new Error('Failed to send task to server');
    }

    const result = await response.json();
    console.log('Task sent successfully', result);
  }
}

export default TaskQueue;
