import db from '../db';

class UserProfile {
  static async getUserById(id) {
    try {
      const user = await db.users.get(id);
      return user;
    } catch (error) {
      console.error('Ошибка при получении пользователя:', error);
    }
  }

  static async updateUser(id, updatedData) {
    try {
      await db.users.clear();
      await db.users.add({ id, ...updatedData });

      const updatedUser = await db.users.get(id);
      return updatedUser;
    } catch (error) {
      console.error('Ошибка при обновлении пользователя:', error);
      throw error;
    }
  }

  static async getAllUsers() {
    try {
      const users = await db.users.toArray();
      return users;
    } catch (error) {
      console.error('Ошибка при получении всех пользователей:', error);
    }
  }
}

export default UserProfile;
