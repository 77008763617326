import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Box,
} from '@mui/material';
import { Link } from 'react-router-dom';
import {
  getAllTournaments,
  getAllArchiveTournaments,
} from '../db/Tournaments/getAllTournaments';

import { COMPETITION_FORMAT, COMPETITION_FORMAT_DIS } from '../settings';

const TournamentList = () => {
  const [tournaments, setTournaments] = useState([]);
  const [showActive, setShowActive] = useState(true);

  useEffect(() => {
    const fetchTournaments = async () => {
      try {
        const data = showActive
          ? await getAllTournaments()
          : await getAllArchiveTournaments();
        setTournaments(data);
      } catch (error) {
        console.error('Ошибка загрузки данных турниров:', error);
      }
    };
    fetchTournaments();
  }, [showActive]);

  return (
    <div>
      <Box display='flex' mb={2} pt={2}>
        <Button
          variant={showActive ? 'contained' : ''}
          onClick={() => setShowActive(true)}
        >
          Активные турниры
        </Button>
        <Button
          variant={!showActive ? 'contained' : ''}
          onClick={() => setShowActive(false)}
          style={{ marginLeft: '10px' }}
        >
          Архивные турниры
        </Button>
      </Box>
      <Grid container spacing={2} justifyContent='center' alignItems='center'>
        {tournaments.length === 0 ? (
          <Grid item xs={12} style={{ textAlign: 'center', marginTop: '20px' }}>
            <Typography variant='h6'>Список турниров пустой</Typography>
          </Grid>
        ) : (
          tournaments.map(tournament => (
            <Grid item key={tournament.id} xs={12} sm={12} md={6}>
              <Link
                to={`/tournament/${tournament.id}`}
                style={{ textDecoration: 'none' }}
              >
                <Card>
                  <CardContent>
                    <Typography variant='h5' component='div'>
                      {tournament.tournament_name}{' '}
                      {tournament.count_cups > 1 ? (
                        <span>(этап № {tournament.current_count_cup})</span>
                      ) : (
                        <></>
                      )}
                    </Typography>
                    <Typography color='textSecondary'>
                      {tournament.water_body}
                    </Typography>
                    <Typography variant='body2'>
                      {tournament.competition_type}.{' '}
                      {tournament.competition_format ===
                      COMPETITION_FORMAT.PERSONAL
                        ? COMPETITION_FORMAT_DIS.PERSONAL
                        : tournament.competition_format ===
                            COMPETITION_FORMAT.PERSONAL_TEAM
                          ? COMPETITION_FORMAT_DIS.PERSONAL_TEAM
                          : COMPETITION_FORMAT_DIS.TEAM}
                    </Typography>
                    <Typography variant='body2'>
                      Дата начала: {tournament.start_date}
                    </Typography>
                    <Typography variant='body2'>
                      Дата окончания: {tournament.end_date}
                    </Typography>
                    <Typography variant='body2'>
                      Текущий статус: {tournament.status_cup}
                    </Typography>
                    <Typography variant='body2'>
                      Ваша роль: {tournament.role}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))
        )}
      </Grid>
    </div>
  );
};

export default TournamentList;
