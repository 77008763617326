import React, { useState, useImperativeHandle, forwardRef } from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DRAW_TYPE } from '../../settings';
const TournamentDraw = forwardRef(({ value, onChange }, ref) => {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');

  useImperativeHandle(ref, () => ({
    validate: () => {
      if (value) {
        setError(false);
        setHelperText('');
        return true;
      } else {
        setError(true);
        setHelperText('Выберите вид жеребьевки');
        return false;
      }
    },
  }));
  const handleInputChange = event => {
    setError(false);
    setHelperText('');

    onChange(event.target.value);
  };

  return (
    <FormControl sx={{ width: '100%', mt: 1 }} size='small' error={error}>
      <InputLabel id='tournament-draw-label'>Вид жеребьевки </InputLabel>
      <Select
        labelId='tournament-draw-label'
        id='tournament-draw'
        value={value}
        label='Вид жеребьевки'
        onChange={handleInputChange}
      >
        <MenuItem value={DRAW_TYPE[0].label}>
          <div>
            <div>{DRAW_TYPE[0].label}</div>
            <small style={{ color: 'gray' }}>{DRAW_TYPE[0].dis}</small>
          </div>
        </MenuItem>
        <MenuItem value={DRAW_TYPE[1].label}>
          <div>
            <div>{DRAW_TYPE[1].label}</div>
            <small style={{ color: 'gray' }}>{DRAW_TYPE[1].dis}</small>
          </div>
        </MenuItem>
        <MenuItem value={DRAW_TYPE[2].label}>
          <div>
            <div>{DRAW_TYPE[2].label}</div>
            <small style={{ color: 'gray' }}>{DRAW_TYPE[2].dis}</small>
          </div>
        </MenuItem>
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
});
export default TournamentDraw;
