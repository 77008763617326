export const VERSION = '0.3.59';

// export const RATING_COMPETITION = 'Рейтинговое соревнование';
// export const COMMERCIAL_COMPETITION = 'Коммерческое соревнование';
// export const PERSONAL = 'Личные';
// export const TEAM = 'Командные';
// export const PERSONAL_TEAM = 'Лично - командные';
export const WEIGH_FISH = 'По общему весу';
export const COUNT_FISH = 'По количеству рыбы';
export const COUNT_BIG_FISH = 'По крупным рыбам';
export const BIG_FISH_DAY = 'Big fish суток';
export const FIRST_FISH = 'Первая пойманная рыба';
export const ROLE = {
  ORGANIZER: 'Организатор',
  CHIEF_JUDGE: 'Главный судья',
  ASSISTANT_CHIEF_JUDGE: 'Заместитель главного судьи',
  CHIEF_SECRETARY: 'Главный секретарь',
  ASSISTANT_CHIEF_SECRETARY: 'Заместитель главного секретаря',
  SENIOR_JUDGE: 'Старший судья',
  JUDGE: 'Судья',
  JUDGE_CONTROLLER: 'Судья - контролер',
  JUDGE_SECRETARY: 'Судья - секретарь',
};

export const TOURNAMENT_STATUS = {
  SET: 'Создан',
  REGISTRATION: 'Регистрация',
  DRAW: 'Жеребьёвка',
  WEIGHING: 'Взвешивание',
  FINISH: 'Турнир завершен',
};
export const COMPETITION_TYPE = {
  RATING_COMPETITION: 'Рейтинговое соревнование',
  COMMERCIAL_COMPETITION: 'Коммерческое соревнование',
};

export const COMPETITION_FORMAT = {
  PERSONAL: 'Парные соревнования',
  TEAM: 'Командные соревнования',
  PERSONAL_TEAM: 'Парно - командные соревнования',
};
export const COMPETITION_FORMAT_DIS = {
  PERSONAL:
    'Парные соревнования (мужчины, женщины), код дисциплины 0920151811Л',
  TEAM: 'Командные соревнования (мужчины, женщины), код дисциплины 0920151811Л',
  PERSONAL_TEAM:
    'Парно - командные соревнования (мужчины, женщины), коды дисциплин 0920151811Л, 0920151811Л',
};
export const COMPETITION_FORMAT_DIS_SELECT = {
  PERSONAL: 'Мужчины, женщины. Код дисциплины 0920151811Л',
  TEAM: 'Мужчины, женщины. Код дисциплины 0920151811Л',
  PERSONAL_TEAM: 'Мужчины, женщины. Коды дисциплин 0920151811Л, 0920151811Л',
};

export const DRAW_TYPE = [
  { key: 1, label: 'Простая', dis: 'Очередь -> сектор' },
  { key: 2, label: 'Раздельная', dis: 'Очередь -> зона -> сектор' },
  {
    key: 3,
    label: 'Готовыми комбинациями секторов',
    dis: 'Очередь -> комбинация -> сектор из комбинации',
  },
];
export const WATER = [
  {
    key: 1,
    title: 'РСК Голодяевский',
    id: 1,
    address: 'Пензенская область, Мокшанский район, с. Нечаевка',
    map: 1,
  },

  {
    key: 2,
    title: 'РСК Кужора',
    id: 2,
    address:
      'Республика Адыгея, Майкопский район, Кужорское сельское поселение',
    map: 0,
  },
  {
    key: 3,
    title: 'РСК Клондайк',
    id: 2,
    address:
      'посёлок Заречный, Березанское сельское поселение, Выселковский район, Краснодарский край',
    map: 0,
  },
  {
    key: 4,
    title: 'Каневской',
    id: 4,
    address: 'Краснодарский край, Каневской район',
    map: 0,
  },
  { key: 5, title: 'Белый лис', id: 5, address: 'Пензенская область', map: 0 },
  { key: 6, title: 'Высокополье', id: 6, address: 'Липецкая область', map: 0 },
  {
    key: 7,
    title: 'РСК Павловский',
    id: 7,
    address: 'Рязанская область, д. Чичкино',
    map: 0,
  },
  {
    key: 8,
    title: 'РСК Борисов Пруд',
    id: 8,
    address: 'Ленинградская область, Ломоносовский район, деревня Новое Калище',
    map: 0,
  },
  {
    key: 9,
    title: 'Украинка',
    id: 9,
    address: 'Республика Крым, Белогорский район, с. Украинка',
  },
  {
    key: 10,
    title: 'Стадион',
    id: 10,
    address: 'Курская область, Курский р-н, д. Нижнекасиново',
    map: 0,
  },
  { key: 11, title: 'Улово', id: 11, address: 'Владимирская область', map: 0 },
  {
    key: 12,
    title: 'РСК Камень',
    id: 12,
    address: 'Курская область, Поныровский р-он, д. Горяйново',
    map: 0,
  },
  {
    key: 13,
    title: 'Просянка',
    id: 13,
    address: 'Ставропольский край, с. Просянка',
    map: 0,
  },
  {
    key: 14,
    title: 'Золотой берег',
    id: 14,
    address: 'Краснодарский край, ст. Динская',
    map: 0,
  },
  { key: 14, title: 'Генезис', id: 14, address: 'Московская область', map: 0 },
  {
    key: 15,
    title: 'Гавриловcкий',
    id: 15,
    address: 'Самарская область, Алексеевский район, д.Гавриловка',
    map: 0,
  },
  {
    key: 16,
    title: 'Братковка',
    id: 16,
    address: 'Краснодарский край',
    map: 0,
  },
];
