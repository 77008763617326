import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from '@mui/material';
import QRCode from 'qrcode.react';

const QRCodeModal = ({ open, onClose, data, int_number }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>QR-код судьи.</DialogTitle>
      <DialogContent>
        <QRCode value={JSON.stringify(data)} size={200} />
      </DialogContent>

      <DialogTitle>Числовой: {int_number}</DialogTitle>
      <DialogActions>
        <Button onClick={onClose} color='secondary'>
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QRCodeModal;
