import React, { useState, useImperativeHandle, forwardRef } from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { COMPETITION_TYPE } from '../../settings';
const TournamentType = forwardRef(({ value, onChange }, ref) => {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');

  useImperativeHandle(ref, () => ({
    validate: () => {
      if (value) {
        setError(false);
        setHelperText('');
        return true;
      } else {
        setError(true);
        setHelperText('Выберите статус соревнования');
        return false;
      }
    },
  }));
  const handleInputChange = event => {
    setError(false);
    setHelperText('');
    onChange(event.target.value);
  };

  return (
    <FormControl sx={{ width: '100%', mt: 1 }} size='small' error={error}>
      <InputLabel id='tournament-type-label'>Статус соревнования</InputLabel>
      <Select
        labelId='tournament-type-label'
        id='tournament-type'
        value={value}
        label='Статус соревнования'
        onChange={handleInputChange}
      >
        <MenuItem value={COMPETITION_TYPE.RATING_COMPETITION}>
          {COMPETITION_TYPE.RATING_COMPETITION}
        </MenuItem>
        <MenuItem value={COMPETITION_TYPE.COMMERCIAL_COMPETITION}>
          {COMPETITION_TYPE.COMMERCIAL_COMPETITION}
        </MenuItem>
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
});
export default TournamentType;
